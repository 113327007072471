<template>
  <v-theme-provider dark>
    <base-section id="contact-us" class="primary" space="36">
      <v-row align="center" class="ma-0" justify="center">
        <base-title space="0" :title="$ml.get('subscribe_header')" />

        <v-responsive class="px-4 mx-6" max-width="600" width="100%">
          <v-form ref="form" lazy-validation v-model="formvalid">
            <base-text-field
              hide-details
              label="Your Email Address"
              v-model="email"
              type="email"
              :rules="mailrules"
              :counter="50"
              required
            >
              <template v-slot:append-outer>
                <v-btn
                  class="ml-n1"
                  height="40"
                  outlined
                  style="margin-top: -7px"
                  v-on:click="sendRequest()"
                >
                  {{ $ml.get("subscribe") }}
                </v-btn>
              </template>
            </base-text-field>
          </v-form>
        </v-responsive>
      </v-row>
    </base-section>
  </v-theme-provider>
</template>

<script>
import axios from "axios";
var serverUrl = "https://syrf.info";
export default {
  name: "SectionNewsletterAlt",
  data: () => ({
    email: "",
    formvalid: true,
    mailrules: [
      //правила для валидации инпутов
      (value) => !!value || "Required.",
      (value) => (value || "").length <= 50 || "Max 50 characters",
      (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    ],
  }),
  methods: {
    sendRequest() {
      //this.$refs.form.resetValidation();
      if (!this.$refs.form.validate()) return;
      var message = {
        name: '',
        email: this.email,
        subject: 'Добавился в рассылку',
        text: this.text,
      };
      console.log(message);
      const headers = { "Content-Type": "application/json", charset: "UTF-8" };
      axios
        .post(serverUrl + "/api/SendMessage", message, { headers })
        .then((response) => {
            if(this.$ml.current == 'ru'){
                alert("Ваш email успешно добавлен в рассылку");
            }else{
                alert("Your email has been successfully added to the mailing list");
            }          
        });
    },
  },
};
</script>
